<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar-menu></sidebar-menu>
        <div class="page-wrapper">
            <div class="content">
                <pageheader :title="title" :title1="title1" />
                <!-- /product list -->
                <div class="activity">
                    <div class="activity-box">
                        <ul class="activity-list">
                            <li v-for="(item, index) in data" :key="index">
                                <!-- <div class="activity-user">
                                    <router-link to="profile" title="" data-toggle="tooltip"
                                        data-original-title="Lesley Grauer">
                                        <img alt="Lesley Grauer" :src="require(`../../../assets/img/customer/${item.img}`)"
                                            class="img-fluid" />
                                    </router-link>
                                </div> -->
                                <a href="javascript:void(0);"
                                    class="text-secondary position-absolute top-0 end-0 me-2 mt-2">{{
                                        item.dob
                                    }}</a>
                                <span class="fw-bold">{{ item.nama }}</span>

                                <div class="timeline-content">

                                    <router-link to="profile" class="name">{{ item.pesan }}</router-link>

                                </div>
                                <div class="activity-content">

                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- /product list -->
            </div>
        </div>
    </div>
</template>
<script>

import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup } from "@/assets/js/function";

export default {
    data() {
        return {
            data: [
                {
                    "id": 1,
                    "img": "profile3.jpg",
                    "name": "Elwis Mathew",
                    "product": "added a new product",
                    "phone": "Redmi Pro 7 Mobile",
                    "time": "4 mins ago"
                },

            ],
            title: "Birthday",
            title1: "View all employees birthday on this month",

            //data
            api: "",
            config: "",
        };
    },
    name: "activities",

    created() {
        const token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }
        this.index();
    }
    ,
    methods: {
        index() {
            ShowLoading();
            let formData = new FormData();
            const newDate = new Date();
            const year = newDate.getFullYear();
            const month = String(newDate.getMonth() + 1).padStart(2, '0');
            // const day = String(newDate.getDate()).padStart(2, '0');

            const formattedDate = `${year}-${month}`;
            formData.append("periode", formattedDate);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'dashboard_bday';
            } else {
                this.api = base_url + 'dashboard_bday';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.data = response.data.data;
                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });

        },
    }
};
</script>
  