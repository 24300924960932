<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">
              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Date From<span class="text-danger">*</span></label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker v-model="startDate" class="picker" :editable="true" :clearable="false" required />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Date to<span class="text-danger">*</span></label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker v-model="endDate" class="picker" :editable="true" :clearable="false" required />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>SQLlite File <span class="text-danger">*</span></label>
                    <input type="file" class="form-control" ref="sqlite_file" accept=".db" required />
                  </div>
                </div>
                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Import</button>
                  <router-link to="historyattendance" class="btn btn-cancel">Cancel</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom } from "@/assets/js/function";

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  // components: {
  //   Form,
  //   Field,
  // },
  name: "editemployee",
  data() {
    return {
      filter: true,
      title: "Import Attendance",
      title1: "manage your employees attendance",
      config: "",
      api: "",

      //data
      currentData: {
      },
      currentDate: new Date,
      startDate: new Date,
      endDate: new Date,
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  methods: {

    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    insert() {
      ShowLoading();
      let formData = new FormData();

      // Object.entries(this.currentData).forEach(([key, value]) => {
      //   formData.append(key, value);
      // });

      const newDate = new Date(this.startDate);
      let year = newDate.getFullYear();
      let month = String(newDate.getMonth() + 1).padStart(2, '0');
      let day = String(newDate.getDate()).padStart(2, '0');

      const formattedDate1 = `${year}-${month}-${day}`;

      const newDate2 = new Date(this.endDate);
      year = newDate2.getFullYear();
      month = String(newDate2.getMonth() + 1).padStart(2, '0');
      day = String(newDate2.getDate()).padStart(2, '0');

      const formattedDate2 = `${year}-${month}-${day}`;

      formData.append("dfrom", formattedDate1);
      formData.append("dto", formattedDate2);
      formData.append("db", this.$refs.sqlite_file.files[0]);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'applications/anviz_import_temp';
      } else {
        this.api = base_url + 'applications/anviz_import_temp';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            // goPage('historyattendance')
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>
