<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">
              <div class="row">
                <div class="col-lg-12 col-sm-6 col-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-between ">
                      <label>Employee <span class="text-danger">*</span></label>
                    </div>
                    <!-- <model-select :options="data_marketing" placeholder="Select Marketing"
                      v-model="this.currentData.id_marketing" style="z-index:500" :isError="marketingCek">
                    </model-select> -->
                    <multi-select :options="needData1" :selected-options="employees" style="z-index:504"
                      placeholder="select Employee" @select="onSelectEmployees">
                    </multi-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Date <span class="text-danger">*</span></label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker v-model="currentData.tanggal" class="picker" :editable="true" :clearable="false"
                        required />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Clock in<span class="text-danger">*</span></label>
                    <input type="time" id="jam_masuk" name="jam_masuk" v-model="currentData.jam_masuk">
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Clock out<span class="text-danger">*</span></label>
                    <input type="time" id="jam_keluar" name="jam_keluar" v-model="currentData.jam_keluar">
                  </div>
                </div>

                <!-- <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <div class="d-flex justify-content-between ">
                      <label>Employee <span class="text-danger">*</span></label>
                    </div>
                    <multi-select :options="needData1" :selected-options="employees" style="z-index:504"
                      placeholder="select Employee" @select="onSelectEmployees">
                    </multi-select>
                  </div>
                </div> -->
                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Save</button>
                  <router-link to="schedule" class="btn btn-cancel">Cancel</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.form-group input[type="datetime-local"] {
  border: 1px solid rgba(145, 158, 171, 0.32);
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #637381;
  padding: 10px 15px;
  border-radius: 5px;
}

.form-group input[type="time"] {
  border: 1px solid rgba(145, 158, 171, 0.32);
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #637381;
  padding: 10px 15px;
  border-radius: 5px;
}

.form-group input[type="datetime-local"]:focus {
  border: 1px solid rgba(82, 183, 127, 255);

}

.form-group input[type="time"]:focus {
  border: 1px solid rgba(82, 183, 127, 255);

}
</style>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom } from "@/assets/js/function";
import { ModelSelect, MultiSelect } from 'vue-search-select';

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {

    ModelSelect,
    MultiSelect

  },
  name: "editemployee",
  data() {
    return {
      filter: true,
      title: "Insert News & Events",
      title1: "add new news and events",
      config: "",
      api: "",
      value: "",

      //data
      currentData: {
        tanggal: new Date(),

      },
      currentDate: new Date,
      needData1: [],
      needData2: [],

      ///multi select
      locations: [],
      employees: [],

      lastSelectItemLocation: {},
      lastSelectItemEmployee: {},
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    this.preparedData();
    // this.LocationList.push({ id: 0, value: 0, text: 'SEMUA' });

  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  methods: {

    preparedData() {

      const needData1 = JSON.parse(localStorage.getItem('needData1'));

      needData1.forEach((item, index) => {
        const item_prepare = { value: item.id, text: item.nama + ' (' + item.telepon + ')' };
        this.needData1.push(item_prepare);
      });
      // const index = this.needData1.findIndex((record) => record.value == this.currentData.employee_id);

      // this.currentData.nama = this.needData1[index].text;
      // this.currentData.tanggal = new Date(this.currentData.tanggal);
      this.employees = [];



    },
    onSelectLocations(items, lastSelectItem) {
      this.locations = items
      this.lastSelectItemLocation = lastSelectItem
    },
    onSelectEmployees(items, lastSelectItem) {
      this.employees = items
      this.lastSelectItemEmployee = lastSelectItem
    },

    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);

      });

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'owners/schedule/insert';
      } else {
        this.api = base_url + 'owners/schedule/insert';
      }

      formData.delete("tanggal");
      if (this.currentData.tanggal != 'Invalid Date') {
        var formattedDate = this.currentData.tanggal.toISOString().split('T')[0];
        formData.append("tanggal", formattedDate);
      }
      let employee_id = [];
      this.employees.forEach((item, index) => {
        employee_id.push(item.value)
      });
      formData.append('employee_id', employee_id.join(','));

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }


      axios
        .post(this.api, formData, this.config)
        .then((response) => {

          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage('schedule');
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>
