<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <headerpos></headerpos>
    <div class="page-wrapper ms-0">
      <div class="content">
        <div class="row">
          <div class="col-lg-8 col-sm-12 tabs_wrapper">
            <div class="page-header">
              <div class="page-title">
                <h4>Categories</h4>
                <h6>Manage your purchases</h6>
              </div>
            </div>
            <div class="owl-caousel-tab">
              <carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
                <slide class="tabs owl-product border-0" v-for="item in pos" :key="item.id">
                  <li :id="item.posid" :class="item.class" @click="active_id = item.id">
                    <div class="product-details">
                      <img :src="require(`../../../assets/img/product/${item.img}`)" alt="img" />
                      <h6>{{ item.name }}</h6>
                    </div>
                  </li>
                </slide>
                <template #addons>
                  <navigation />
                </template>
              </carousel>
            </div>
            <div class="tabs_container">
              <div data-tab="fruits">
                <div class="row">
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill active">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product29.jpg" alt="img" />
                        <h6>Qty: 5.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Fruits</h5>
                        <h4>Orange</h4>
                        <h6>150.00</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product31.jpg" alt="img" />
                        <h6>Qty: 1.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Fruits</h5>
                        <h4>Strawberry</h4>
                        <h6>15.00</h6>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product35.jpg" alt="img" />
                        <h6>Qty: 5.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Fruits</h5>
                        <h4>Banana</h4>
                        <h6>150.00</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product37.jpg" alt="img" />
                        <h6>Qty: 5.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Fruits</h5>
                        <h4>Limon</h4>
                        <h6>1500.00</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product54.jpg" alt="img" />
                        <h6>Qty: 5.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Fruits</h5>
                        <h4>Apple</h4>
                        <h6>1500.00</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab_content" data-tab="headphone">
                <div class="row">
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product44.jpg" alt="img" />
                        <h6>Qty: 5.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Headphones</h5>
                        <h4>Earphones</h4>
                        <h6>150.00</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product45.jpg" alt="img" />
                        <h6>Qty: 5.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Headphones</h5>
                        <h4>Earphones</h4>
                        <h6>150.00</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product36.jpg" alt="img" />
                        <h6>Qty: 5.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Headphones</h5>
                        <h4>Earphones</h4>
                        <h6>150.00</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab_content" data-tab="Accessories">
                <div class="row">
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product32.jpg" alt="img" />
                        <h6>Qty: 1.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Accessories</h5>
                        <h4>Sunglasses</h4>
                        <h6>15.00</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product46.jpg" alt="img" />
                        <h6>Qty: 1.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Accessories</h5>
                        <h4>Pendrive</h4>
                        <h6>150.00</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product55.jpg" alt="img" />
                        <h6>Qty: 1.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Accessories</h5>
                        <h4>Mouse</h4>
                        <h6>150.00</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab_content" data-tab="Shoes">
                <div class="row">
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product60.jpg" alt="img" />
                        <h6>Qty: 1.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Shoes</h5>
                        <h4>Red nike</h4>
                        <h6>1500.00</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab_content" data-tab="computer">
                <div class="row">
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product56.jpg" alt="img" />
                        <h6>Qty: 1.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Computers</h5>
                        <h4>Desktop</h4>
                        <h6>1500.00</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab_content" data-tab="Snacks">
                <div class="row">
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product47.jpg" alt="img" />
                        <h6>Qty: 1.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Snacks</h5>
                        <h4>Duck Salad</h4>
                        <h6>1500.00</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product48.png" alt="img" />
                        <h6>Qty: 1.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Snacks</h5>
                        <h4>Breakfast board</h4>
                        <h6>1500.00</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product57.jpg" alt="img" />
                        <h6>Qty: 1.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Snacks</h5>
                        <h4>California roll</h4>
                        <h6>1500.00</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product58.jpg" alt="img" />
                        <h6>Qty: 1.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Snacks</h5>
                        <h4>Sashimi</h4>
                        <h6>1500.00</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab_content" data-tab="watch">
                <div class="row">
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product49.jpg" alt="img" />
                        <h6>Qty: 1.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h4>Watch</h4>
                        <h5>Watch</h5>
                        <h6>1500.00</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product51.jpg" alt="img" />
                        <h6>Qty: 1.00</h6>
                      </div>
                      <div class="productsetcontent">
                        <h4>Watch</h4>
                        <h5>Watch</h5>
                        <h6>1500.00</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab_content" data-tab="cycle">
                <div class="row">
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product52.jpg" alt="img" />
                        <h6>Qty: 1.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h4>Cycle</h4>
                        <h5>Cycle</h5>
                        <h6>1500.00</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product53.jpg" alt="img" />
                        <h6>Qty: 1.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h4>Cycle</h4>
                        <h5>Cycle</h5>
                        <h6>1500.00</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab_content" data-tab="fruits1">
                <div class="row">
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product29.jpg" alt="img" />
                        <h6>Qty: 5.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Fruits</h5>
                        <h4>Orange</h4>
                        <h6>150.00</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product31.jpg" alt="img" />
                        <h6>Qty: 1.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Fruits</h5>
                        <h4>Strawberry</h4>
                        <h6>15.00</h6>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product35.jpg" alt="img" />
                        <h6>Qty: 5.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Fruits</h5>
                        <h4>Banana</h4>
                        <h6>150.00</h6>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product37.jpg" alt="img" />
                        <h6>Qty: 5.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Fruits</h5>
                        <h4>Limon</h4>
                        <h6>1500.00</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab_content" data-tab="headphone1">
                <div class="row">
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product44.jpg" alt="img" />
                        <h6>Qty: 5.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Headphones</h5>
                        <h4>Earphones</h4>
                        <h6>150.00</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product45.jpg" alt="img" />
                        <h6>Qty: 5.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Headphones</h5>
                        <h4>Earphones</h4>
                        <h6>150.00</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6 d-flex">
                    <div class="productset flex-fill">
                      <div class="productsetimg">
                        <img src="../../../assets/img/product/product36.jpg" alt="img" />
                        <h6>Qty: 5.00</h6>
                        <div class="check-product">
                          <i class="fa fa-check"></i>
                        </div>
                      </div>
                      <div class="productsetcontent">
                        <h5>Headphones</h5>
                        <h4>Earphones</h4>
                        <h6>150.00</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="order-list">
              <div class="orderid">
                <h4>Order List</h4>
                <h5>Transaction id : #65565</h5>
              </div>
              <div class="actionproducts">
                <ul>
                  <li>
                    <a href="javascript:void(0);" class="deletebg confirm-text" @click="showAlert"><img
                        src="../../../assets/img/icons/delete-2.svg" alt="img" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="false" class="dropset">
                      <img src="../../../assets/img/icons/ellipise1.svg" alt="img" />
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton" data-popper-placement="bottom-end">
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item">Action</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item">Another Action</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item">Something Elses</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="card card-order">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <a href="javascript:void(0);" class="btn btn-adds" data-bs-toggle="modal" data-bs-target="#create"><i
                        class="fa fa-plus me-2"></i>Add Customer</a>
                  </div>
                  <div class="col-lg-12">
                    <div class="select-split">
                      <div class="select-group w-100">
                        <vue-select :options="Walk" placeholder="Walk-in Customer" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="select-split">
                      <div class="select-group w-100">
                        <vue-select :options="Product" placeholder="Product" />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="text-end">
                      <a class="btn btn-scanner-set"><img src="../../../assets/img/icons/scanner1.svg" alt="img"
                          class="me-2" />Scan bardcode</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="split-card"></div>
              <div class="card-body pt-0">
                <div class="totalitem">
                  <h4>Total items : 4</h4>
                  <a href="javascript:void(0);">Clear all</a>
                </div>
                <div class="product-table">
                  <ul class="product-lists" v-for="(item, index) in products" :key="item.id">
                    <li>
                      <div class="productimg">
                        <div class="productimgs">
                          <img :src="require(`../../../assets/img/product/${item.img}`)" alt="img" />
                        </div>
                        <div class="productcontet">
                          <h4>
                            {{ item.name }}
                            <a href="javascript:void(0);" class="ms-2" data-bs-toggle="modal" data-bs-target="#edit"><img
                                src="../../../assets/img/icons/edit-5.svg" alt="img" /></a>
                          </h4>
                          <div class="productlinkset">
                            <h5>PT001</h5>
                          </div>
                          <div class="increment-decrement">
                            <div class="input-groups">
                              <input type="button" value="-" class="button-minus dec button" @click="decrement(index)" />
                              <div class="ps-2 pe-2">{{ item.count }}</div>
                              <input type="button" value="+" class="button-plus inc button" @click="increment(index)" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>3000.00</li>
                    <li>
                      <a class="confirm-text" href="javascript:void(0);" @click="showAlert"><img
                          src="../../../assets/img/icons/delete-2.svg" alt="img" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="split-card"></div>
              <div class="card-body pt-0 pb-2">
                <div class="setvalue">
                  <ul>
                    <li>
                      <h5>Subtotal</h5>
                      <h6>55.00$</h6>
                    </li>
                    <li>
                      <h5>Tax</h5>
                      <h6>5.00$</h6>
                    </li>
                    <li class="total-value">
                      <h5>Total</h5>
                      <h6>60.00$</h6>
                    </li>
                  </ul>
                </div>
                <div class="setvaluecash">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="paymentmethod">
                        <img src="../../../assets/img/icons/cash.svg" alt="img" class="me-2" />
                        Cash
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="paymentmethod">
                        <img src="../../../assets/img/icons/debitcard.svg" alt="img" class="me-2" />
                        Debit
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="paymentmethod">
                        <img src="../../../assets/img/icons/scan.svg" alt="img" class="me-2" />
                        Scan
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="btn-totallabel">
                  <h5>Checkout</h5>
                  <h6>60.00$</h6>
                </div>
                <div class="btn-pos">
                  <ul>
                    <li>
                      <a class="btn"><img src="../../../assets/img/icons/pause1.svg" alt="img" class="me-1" />Hold</a>
                    </li>
                    <li>
                      <a class="btn"><img src="../../../assets/img/icons/edit-6.svg" alt="img"
                          class="me-1" />Quotation</a>
                    </li>
                    <li>
                      <a class="btn"><img src="../../../assets/img/icons/trash12.svg" alt="img" class="me-1" />Void</a>
                    </li>
                    <li>
                      <a class="btn"><img src="../../../assets/img/icons/wallet1.svg" alt="img" class="me-1" />Payment</a>
                    </li>
                    <li>
                      <a class="btn" data-bs-toggle="modal" data-bs-target="#recents"><img
                          src="../../../assets/img/icons/transcation.svg" alt="img" class="me-1" />
                        Transaction</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <posmodal></posmodal>
</template>
<script>
import { ref } from "vue";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
export default {
  setup() {
    //const count = ref(1);
    const increment = async (index) => {
      products.value[index].count++;
    };
    const decrement = async (index) => {
      products.value[index].count--;
    };
    const products = ref([
      { id: 1, name: "Pineapple", img: "product30.jpg", count: 0 },
      { id: 2, name: "Green Nike", img: "product34.jpg", count: 0 },
      { id: 3, name: "Banana", img: "product35.jpg", count: 0 },
      { id: 4, name: "Strawberry", img: "product31.jpg", count: 0 },
    ]);
    return {
      products,
      increment,
      decrement,
    };
  },
  data() {
    return {
      active_id: 1,
      Product: ["Product", "Barcode"],
      Walk: ["Walk-in Customer", "Chris Moris"],
      settings: {
        itemsToShow: 2,
        snapAlign: "center",
      },

      breakpoints: {
        575: {
          itemsToShow: 4,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 6,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 8,
          snapAlign: "start",
        },
      },
      help: [
        {
          id: 1,
          img: "product62.png",
          posid: "fruits",
          name: "Fruits",
        },
        {
          id: 2,
          img: "product63.png",
          posid: "headphone",
          name: "Headphones",
        },
        {
          id: 3,
          img: "product64.png",
          posid: "Accessories",
          name: "Accessories",
        },
        {
          id: 4,
          img: "product65.png",
          posid: "Shoes",
          name: "Shoes",
        },
        {
          id: 5,
          img: "product66.png",
          posid: "computer",
          name: "Computer",
        },
        {
          id: 6,
          img: "product67.png",
          posid: "Snacks",
          name: "Snacks",
        },
        {
          id: 7,
          img: "product68.png",
          posid: "watch",
          name: "Watches",
        },
        {
          id: 8,
          img: "product61.png",
          posid: "cycle",
          name: "Cycles",
        },
        {
          id: 9,
          img: "product62.png",
          posid: "fruits1",
          name: "Fruits",
        },
        {
          id: 10,
          img: "product63.png",
          posid: "headphone1",
          name: "Headphones",
        },
      ],
    };
  },
  mounted() {
    $(".tabs li").click(function () {
      var $this = $(this);
      var $theTab = $(this).attr("active_id");
      console.log($theTab);
      if ($this.hasClass("active")) {
        // do nothing
      } else {
        $this
          .closest(".tabs_wrapper")
          .find(".tabs li, .tabs_container .tab_content")
          .removeClass("active");
        $(
          '.tabs_container .tab_content[data-tab="' +
          $theTab +
          '"], tabs li[id="' +
          $theTab +
          '"]'
        ).addClass("active");
      }
    });
  },
  computed: {
    pos() {
      return this.help.map((item) => {
        item.class = this.active_id === item.id ? "active" : "";
        return item;
      });
    },
  },
  methods: {
    showAlert() {
      // Use sweetalert2
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#FF9F43",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.");
        }
      });
    },
  },
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  name: "pos",
};
</script>
