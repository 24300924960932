<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="update">
              <pageheader title="Employee Data" title1="" />

              <div class="row">
                <!-- <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>ID karyawan</label>
                    <input type="text" v-model="currentEmployee.employee_id" placeholder="" />
                  </div>
                </div> -->
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Employee Name <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentEmployee.nama" placeholder="" required />
                    <!-- <Field name="name" type="text" v-model="currentEmployee.nama" class="form-control" aria-placeholder=""
                      :class="{ 'is-invalid': errors.name }" :maxlength="13" ref="name" />

                    <div class="invalid-feedback">{{ errors.name }}</div>
                    <div class="emailshow text-danger" id="name"></div> -->
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Whatsapp <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentEmployee.telepon" placeholder="ex: 081234567890" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Work Type <span class="text-danger">*</span></label>
                    <vue-select v-model="currentEmployee.tipe_kerja" :options="TipeKerjaList" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Shift </label>
                    <vue-select :options="ShiftList" :settings="{ settingOption: value, settingOption: value }"
                      v-model="currentEmployee.shift_id" placeholder="None">
                    </vue-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Location <span class="text-danger">*</span></label>
                    <vue-select :options="LocationList" :settings="{ settingOption: value, settingOption: value }"
                      v-model="currentEmployee.lokasi_id" placeholder="None" required>
                    </vue-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Type of Attendance <span class="text-danger">*</span></label>
                    <vue-select :options="AttendanceList" v-model="currentEmployee.jenis_absensi" placeholder="None"
                      required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Emergency Contact <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentEmployee.kontak_darurat" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Date of Birth <span class="text-danger">*</span></label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker v-model="currentEmployee.dob" class="picker" :editable="true" :clearable="false"
                        required />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Gender <span class="text-danger">*</span></label>
                    <vue-select :options="GenderList" v-model="currentEmployee.jenis_kelamin" placeholder="None"
                      required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Religion<span class="text-danger">*</span></label>
                    <vue-select :options="ReligionList" v-model="currentEmployee.agama" placeholder="None" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Joining Date <span class="text-danger">*</span></label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker v-model="currentEmployee.join_date" class="picker" :editable="true" :clearable="false"
                        required />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Departement</label>
                    <vue-select :options="DepartementList" :settings="{ settingOption: value, settingOption: value }"
                      v-model="currentEmployee.departemen" placeholder="None">
                    </vue-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Job Title</label>
                    <vue-select :options="JobTitleList" :settings="{ settingOption: value, settingOption: value }"
                      v-model="currentEmployee.jabatan" placeholder="None">
                    </vue-select>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Address</label>
                    <textarea class="form-control" v-model="currentEmployee.alamat">
                    </textarea>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Province</label>
                    <input type="text" v-model="currentEmployee.provinsi" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Regency</label>
                    <input type="text" v-model="currentEmployee.kabupaten" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>District</label>
                    <input type="text" v-model="currentEmployee.kecamatan" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Village</label>
                    <input type="text" v-model="currentEmployee.kelurahan" />
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Account Number</label>
                    <input type="text" v-model="currentEmployee.nomor_rekening" />
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Name of Bank</label>
                    <input type="text" v-model="currentEmployee.nama_bank" />
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Account Holder's Name</label>
                    <input type="text" v-model="currentEmployee.nama_rekening" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>No. NPWP</label>
                    <input type="text" v-model="currentEmployee.nomor_npwp" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>No. BPJS Kesehatan</label>
                    <input type="text" v-model="currentEmployee.nomor_bpjs_kesehatan" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>No. BPJS Ketenaga kerjaan</label>
                    <input type="text" v-model="currentEmployee.nomor_bpjs_tk" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Leave Balance</label>
                    <input type="text" v-model="currentEmployee.saldo_cuti" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Direct Supervisor</label>
                    <vue-select :options="employeeList" :settings="{ settingOption: value, settingOption: value }"
                      v-model="currentEmployee.atasan_langsung" placeholder="None">
                    </vue-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Indirect Supervisor</label>
                    <vue-select :options="employeeList" :settings="{ settingOption: value, settingOption: value }"
                      v-model="currentEmployee.atasan_tidak_langsung" placeholder="None">
                    </vue-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Golongan</label>
                    <vue-select :options="GolonganList" v-model="currentEmployee.golongan" placeholder="None" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Taxation Status</label>
                    <vue-select :options="StatusPajakList" :settings="{ settingOption: value, settingOption: value }"
                      v-model="currentEmployee.status_pajak" placeholder="None" />
                  </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>KTP Image</label>
                    <input type="file" class="form-control" ref="foto_ktp" />
                  </div>
                  <div class="product-list " v-if="currentEmployee.foto_ktp != ''">
                    <ul class="row ">
                      <li :style="{
                        display: filter ? 'block' : 'none',
                      }" class=" w-100">
                        <div class="productviews h-100">
                          <div class="productviewsimg">
                            <img :src="currentEmployee.foto_ktp" alt="img" />
                          </div>
                          <div class="productviewscontent">
                            <div class="productviewsname">
                              <h2>KTP Image</h2>
                            </div>
                            <!-- <a href="javascript:void(0);" class="hideset" v-on:click="filter = !filter">x</a> -->
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Kartu Keluarga Image</label>
                    <input type="file" class="form-control" ref="foto_kartukeluarga" />
                  </div>
                  <div class="product-list " v-if="currentEmployee.foto_kartukeluarga != ''">
                    <ul class="row ">
                      <li :style="{
                        display: filter ? 'block' : 'none',
                      }" class=" w-100">
                        <div class="productviews h-100">
                          <div class="productviewsimg ">
                            <img :src="currentEmployee.foto_kartukeluarga" alt="img" />
                          </div>
                          <div class="productviewscontent">
                            <div class="productviewsname">
                              <h2>Kartu Keluarga Image</h2>
                            </div>
                            <!-- <a href="javascript:void(0);" class="hideset" v-on:click="filter = !filter">x</a> -->
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Account Book Image</label>
                    <input type="file" class="form-control" ref="foto_bukurekening" />
                  </div>
                  <div class="product-list " v-if="currentEmployee.foto_bukurekening != ''">
                    <ul class="row ">
                      <li :style="{
                        display: filter ? 'block' : 'none',
                      }" class=" w-100">
                        <div class="productviews h-100 ">
                          <div class="productviewsimg">
                            <img :src="currentEmployee.foto_bukurekening" alt="img" />
                          </div>
                          <div class="productviewscontent">
                            <div class="productviewsname">
                              <h2>Account Book Image</h2>
                            </div>
                            <!-- <a href="javascript:void(0);" class="hideset" v-on:click="filter = !filter">x</a> -->
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- <div class="col-lg-3 col-sm-6 col-12"></div>
                <div class="col-lg-3">
                  <div class="form-group">
                    <label> Upload Foto KTP</label>
                    <div class="image-upload">
                      <input type="file" class="form-control" ref="foto_ktp" />
                      <div class="image-uploads">
                        <img src="../../../assets/img/icons/upload.svg" alt="img" />
                        <h4>Drag and drop a file to upload</h4>
                      </div>
                    </div>
                  </div>
                  <button type="button" @click="showFile">test</button>
                  <div class="product-list ">
                    <ul class="row ">
                      <li :style="{
                        display: filter ? 'block' : 'none',
                      }" class=" w-100">
                        <div class="productviews">
                          <div class="productviewsimg">
                            <img :src="foto_ktp_img" alt="img" />
                          </div>
                          <div class="productviewscontent">
                            <div class="productviewsname">
                              <h2>macbookpro.jpg</h2>
                              <h3>581kb</h3>
                            </div>
                            <a href="javascript:void(0);" class="hideset" v-on:click="filter = !filter">x</a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div> -->







                <pageheader title="Salary Details" title1="" />
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Salary <span class="text-danger">*</span></label>
                    <div class="d-flex gap-1">
                      <input type="text" v-model="currentEmployee.salary[0].nilai" style="width: 55%;"
                        @input="formatCurrency(currentEmployee.salary[0].nilai, 0)" required />
                      <vue-select :options="TipeKerjaList" v-model="currentEmployee.salary[0].metode" placeholder="None"
                        class="flex-fill" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Fixed Allowance</label>
                    <div class="d-flex gap-1">
                      <input type="text" v-model="currentEmployee.salary[1].nilai" style="width: 55%;"
                        :ref="currentEmployee.salary[1].id"
                        @input="formatCurrency(currentEmployee.salary[1].nilai, 1)" />
                      <vue-select :options="TipeKerjaList" v-model="currentEmployee.salary[1].metode" placeholder="None"
                        class="flex-fill" />
                    </div>

                  </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Attendance Allowance</label>
                    <div class="d-flex gap-1">
                      <input type="text" v-model="currentEmployee.salary[2].nilai" style="width: 55%;"
                        :ref="currentEmployee.salary[2].id"
                        @input="formatCurrency(currentEmployee.salary[2].nilai, 2)" />
                      <vue-select :options="TipeKerjaList" v-model="currentEmployee.salary[2].metode" placeholder="None"
                        class="flex-fill" />
                    </div>

                  </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Meal Allowance</label>
                    <div class="d-flex gap-1">
                      <input type="text" v-model="currentEmployee.salary[3].nilai" style="width: 55%;"
                        :ref="currentEmployee.salary[3].id"
                        @input="formatCurrency(currentEmployee.salary[3].nilai, 3)" />
                      <vue-select :options="TipeKerjaList" v-model="currentEmployee.salary[3].metode" placeholder="None"
                        class="flex-fill" />
                    </div>

                  </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Transport Allowance</label>
                    <div class="d-flex gap-1">
                      <input type="text" v-model="currentEmployee.salary[4].nilai" style="width: 55%;"
                        :ref="currentEmployee.salary[4].id"
                        @input="formatCurrency(currentEmployee.salary[4].nilai, 4)" />
                      <vue-select :options="TipeKerjaList" v-model="currentEmployee.salary[4].metode" placeholder="None"
                        class="flex-fill" />
                    </div>

                  </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Non-fixed Allowance</label>
                    <div class="d-flex gap-1">
                      <input type="text" v-model="currentEmployee.salary[5].nilai" style="width: 55%;"
                        :ref="currentEmployee.salary[5].id"
                        @input="formatCurrency(currentEmployee.salary[5].nilai, 5)" />
                      <vue-select :options="TipeKerjaList" v-model="currentEmployee.salary[5].metode" placeholder="None"
                        class="flex-fill" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Other Allowances</label>
                    <div class="d-flex gap-1">
                      <input type="text" v-model="currentEmployee.salary[6].nilai" style="width: 55%;"
                        :ref="currentEmployee.salary[6].id"
                        @input="formatCurrency(currentEmployee.salary[6].nilai, 6)" />
                      <vue-select :options="TipeKerjaList" v-model="currentEmployee.salary[6].metode" placeholder="None"
                        class="flex-fill" />
                    </div>

                  </div>
                </div>
                <div class="col-lg-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label>Other Allowances 2</label>
                    <div class="d-flex gap-1">
                      <input type="text" v-model="currentEmployee.salary[7].nilai" style="width: 55%;"
                        :ref="currentEmployee.salary[7].id"
                        @input="formatCurrency(currentEmployee.salary[7].nilai, 7)" />
                      <vue-select :options="TipeKerjaList" v-model="currentEmployee.salary[7].metode" placeholder="None"
                        class="flex-fill" />
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Insert</button>
                  <router-link to="employee" class="btn btn-cancel">Cancel</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, formatCurrency, rupiah, rupiahoutput, rupiahInput, AlertBottom, sanitizeForMySQL } from "@/assets/js/function";

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  // components: {
  //   Form,
  //   Field,
  // },
  name: "editemployee",
  data() {
    return {
      filter: true,
      title: "Employee Insert",
      title1: "add new employee",
      config: "",
      api: "",
      foto_ktp_img: "",

      // select option
      TipeKerjaList: ["MONTH", "DAILY"],
      ShiftList: [],
      LocationList: [],
      AttendanceList: ["SCANQR", "SELFIE", "FINGERPRINT"],
      GenderList: ["MALE", "FEMALE"],
      ReligionList: ["ISLAM", "KRISTEN PROTESTAN", "KRISTEN KATOLIK", "HINDU", "BUDDHA", "KONGHUCU"],
      DepartementList: [],
      JobTitleList: [],
      employeeList: [],
      GolonganList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      StatusPajakList: [],

      //data
      currentEmployee: {
        id: "",
        employee_id: "",
        tipe_kerja: "",
        nama: "",
        telepon: "",
        shift_id: "",
        lokasi_id: "",
        jabatan: "",
        departemen: "",
        jenis_absensi: "",
        alamat: "",
        kontak_darurat: "",
        nomor_rekening: "",
        nama_bank: "",
        nama_rekening: "",
        foto_ktp: "",
        foto_kartukeluarga: "",
        foto_bukurekening: "",
        dob: new Date(),
        jenis_kelamin: "",
        agama: "",
        join_date: new Date(),
        terminate_date: "",
        terminate_reason: "",
        provinsi: "",
        kabupaten: "",
        kecamatan: "",
        kelurahan: "",
        nomor_npwp: "",
        nomor_bpjs_tk: "",
        nomor_bpjs_kesehatan: "",
        status_pajak: "",
        golongan: "",
        atasan_langsung: "",
        atasan_tidak_langsung: "",
        saldo_cuti: 0,
        salary: [
          {
            id: "1",
            jenis: "gaji",
            metode: "MONTH",
            nilai: "0",
            status: "AKTIF"
          },
          {
            id: "2",
            jenis: "tunjangan_tetap",
            metode: "MONTH",
            nilai: "0",
            status: "AKTIF"
          },
          {
            id: "3",
            jenis: "tunjangan_kehadiran",
            metode: "MONTH",
            nilai: "0",
            status: "AKTIF"
          },
          {
            id: "4",
            jenis: "tunjangan_makan",
            metode: "MONTH",
            nilai: "0",
            status: "AKTIF"
          },
          {
            id: "5",
            jenis: "tunjangan_transport",
            metode: "MONTH",
            nilai: "0",
            status: "AKTIF"
          },
          {
            id: "6",
            jenis: "tunjangan_tidak_tetap",
            metode: "MONTH",
            nilai: "0",
            status: "AKTIF"
          },
          {
            id: "7",
            jenis: "tunjangan_lain",
            metode: "MONTH",
            nilai: "0",
            status: "AKTIF"
          },
          {
            id: "8",
            jenis: "tunjangan_lain2",
            metode: "MONTH",
            nilai: "0",
            status: "AKTIF"
          }
        ]
      },
      currentDate: new Date,


      Computers: ["Computers", "Mac"],
      None: ["None", "option1"],
      Tax: ["Choose Tax", "2%"],
      Percentage: ["Percentage", "10%", "20%"],
      Piece: ["Piece", "Kg"],
      Active: ["Active", "Open"],
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    //load shift list
    const shift = JSON.parse(localStorage.getItem('shiftList'));
    shift.forEach((value, index) => {
      const element = { id: value.id, text: value.nama_shift + " (" + value.jam_masuk + "-" + value.jam_pulang + ")" }
      this.ShiftList.push(element);
    });

    //load location
    const location = JSON.parse(localStorage.getItem('locationList'));
    location.forEach((value, index) => {
      const element = { id: value.id, text: value.nama_lokasi }
      this.LocationList.push(element);
    });

    //set departement
    const departement = JSON.parse(localStorage.getItem('departemenList'));
    departement.forEach((value, index) => {
      const element = { id: value.id, text: value.nama }
      this.DepartementList.push(element);
    });

    //set jabatan
    const jabatan = JSON.parse(localStorage.getItem('jabatanList'));
    jabatan.forEach((value, index) => {
      const element = { id: value.id, text: value.nama }
      this.JobTitleList.push(element);
    });

    //set employee
    const employee = JSON.parse(localStorage.getItem('employeeList'));
    employee.forEach((value, index) => {
      const element = { id: value.id, text: value.nama }
      this.employeeList.push(element);
    });

    //set pajak 
    const pajak = JSON.parse(localStorage.getItem('statusPajakList'));
    pajak.forEach((value, index) => {
      const element = { id: value.status, text: value.status }
      this.StatusPajakList.push(element);
    });





  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  methods: {
    rupiah, rupiahoutput,
    scrollToInvalidElement() {
      const invalidElement = document.querySelector('.is-invalid');
      if (invalidElement && typeof invalidElement.scrollIntoView === 'function') {
        invalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },

    formatCurrency(number, index) {
      this.currentEmployee.salary[index].nilai = rupiahInput(number);
    },
    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    update() {
      ShowLoading();
      let formData = new FormData();


      Object.entries(this.currentEmployee).forEach(([key, value]) => {
        formData.append(key, sanitizeForMySQL(value));
      });
      //deleteemplpyee id because its necessary
      formData.delete("employee_id");

      //set to indonesia again to save
      formData.delete("tipe_kerja");
      if (this.currentEmployee.tipe_kerja == 'DAILY') {
        formData.append("tipe_kerja", "HARIAN");
      } else {
        formData.append("tipe_kerja", "BULANAN");
      }

      //set to jenis_kelamin to indonesia to save
      formData.delete("jenis_kelamin");
      if (this.currentEmployee.jenis_kelamin == 'MALE') {
        formData.append("jenis_kelamin", "PRIA");
      } else {
        formData.append("jenis_kelamin", "WANITA");
      }
      formData.delete("salary");

      //set salary back to indonesia to save
      formData.append("jenis", "gaji#tunjangan_tetap#tunjangan_kehadiran#tunjangan_makan#tunjangan_transport#tunjangan_tidak_tetap#tunjangan_lain#tunjangan_lain2");

      // set salary
      let metode = "";
      let nilai = "";
      this.currentEmployee.salary.forEach((value, index) => {
        if (index != 7) {
          if (this.currentEmployee.salary[index].metode == "MONTH") {
            metode = metode + "BULANAN#"
          } else {
            metode = metode + "HARIAN#"
          }
          nilai = nilai + rupiahoutput(this.currentEmployee.salary[index].nilai) + "#"
        } else {
          if (this.currentEmployee.salary[index].metode == "MONTH") {
            metode = metode + "BULANAN"
          } else {
            metode = metode + "HARIAN"
          }
          nilai = nilai + rupiahoutput(this.currentEmployee.salary[index].nilai)
        }
      });
      formData.append("metode", metode);
      formData.append("nilai", nilai);

      //set dob
      formData.delete("dob");
      if (this.currentEmployee.dob != 'Invalid Date') {
        var formattedDate = this.currentEmployee.dob.toISOString().split('T')[0];
        formData.append("dob", formattedDate);
      }
      //set join date
      formData.delete("join_date");
      console.log(this.currentEmployee.join_date);
      if (this.currentEmployee.join_date != 'Invalid Date') {
        var formattedDate = this.currentEmployee.join_date.toISOString().split('T')[0];
        formData.append("join_date", formattedDate);
      }

      //set image
      formData.delete("foto_ktp");
      formData.delete("foto_bukurekening");
      formData.delete("foto_kartukeluarga");

      if (this.$refs.foto_ktp.value != "") {
        formData.append("foto_ktp", this.$refs.foto_ktp.files[0]);
      }
      if (this.$refs.foto_bukurekening.value != "") {
        formData.append("foto_bukurekening", this.$refs.foto_bukurekening.files[0]);
      }
      if (this.$refs.foto_kartukeluarga.value != "") {
        formData.append("foto_kartukeluarga", this.$refs.foto_kartukeluarga.files[0]);
      }

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'employees/insert';
      } else {
        this.api = base_url + 'employees/insert';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage("employee");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });




    },
  },

};
</script>
