<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="update">
              <div class="text-danger" v-if="pesanRegister != ''"> {{ pesanRegister }}</div>
              <div class="row" v-else>
                <div v-html="description" class="mb-3">

                </div>
                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">
                    <span v-if="isOn == false">Allow Access </span>
                    <span v-else>Remove Access </span>
                  </button>
                  <router-link to="connectedapps" class="btn btn-cancel">Cancel</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, formatCurrency, rupiah, rupiahoutput, rupiahInput, AlertBottom, refreshPage } from "@/assets/js/function";

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  // components: {
  //   Form,
  //   Field,
  // },
  name: "editlocation",
  data() {
    return {
      filter: true,
      title: "Connected Apps ",
      title1: "manage your apps",
      config: "",
      api: "",
      foto_ktp_img: "",
      isOn: "",
      callback_on_off: "",
      description: "",
      pesanRegister: "",

      //data
      currentData: {
      },


      currentDate: new Date,
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    //get data
    this.currentData = JSON.parse(localStorage.getItem('currentData'));

    if (this.currentData.callback_register == undefined) {
      this.cekStatus();

    } else {
      this.register();

    }


  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  methods: {
    rupiah, rupiahoutput,
    formatCurrency(number, index) {
      this.currentEmployee.salary[index].nilai = rupiahInput(number);
    },
    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    update() {
      ShowLoading();

      if (process.env.NODE_ENV === "development") {
        this.api = this.callback_on_off;
      } else {
        this.api = this.callback_on_off;
      }

      axios
        .post(this.api, '', this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.data.message);

            if (response.data.data.callback_on == undefined) {
              this.isOn = false;
              this.callback_on_off = response.data.data.callback_on;
              refreshPage();

            } else {
              this.isOn = true;
              this.callback_on_off = response.data.data.callback_off;
              refreshPage();
            }
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.data.message, 1500, false);

          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    cekStatus(url) {
      ShowLoading();


      if (process.env.NODE_ENV === "development") {
        if (url == undefined) {
          this.api = this.currentData.callback_status;
        } else {
          this.api = url;
        }
      } else {
        if (url == undefined) {
          this.api = this.currentData.callback_status;
        } else {
          this.api = url;
        }
      }

      axios
        .post(this.api, '', this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.data.message);
            if (response.data.data.callback_on == undefined) {
              this.isOn = true;
              this.callback_on_off = response.data.data.callback_off;
              this.description = response.data.data.description;
            } else {
              this.isOn = false;
              this.callback_on_off = response.data.data.callback_on;
              this.description = response.data.data.description;
            }
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.data.message, 1500, false);

          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

    register() {
      ShowLoading();


      if (process.env.NODE_ENV === "development") {
        this.api = this.currentData.callback_register;
      } else {
        this.api = this.currentData.callback_register;
      }

      axios
        .post(this.api, '', this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.data.message);
            if (response.data.data.callback_refresh_token == undefined) {
              console.log('tidak ada callbak register');

            } else {
              this.refreshToken(this.response.data.data.callback_refresh_token);


            }
            CloseLoading();
          }
          if (response.data.status == false) {
            // AlertPopup("error", "", response.data.data.message, 1500, false);
            this.pesanRegister = response.data.data.message;
            CloseLoading();

          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

    refreshToken(url) {
      ShowLoading();

      if (process.env.NODE_ENV === "development") {
        this.api = url;
      } else {
        this.api = url;
      }

      axios
        .post(this.api, '', this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.data.message);
            if (response.data.data.callback_status == undefined) {
              console.log('tidak ada callbak status');
            } else {
              this.cekStatus(response.data.data.callback_status);
            }
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    }


  },

};
</script>
