<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">
              <div class="row mb-3" v-for="(   record, index   ) in DataList   " :key="record.id">
                <div class="col-lg-6 col-sm-6 col-12">
                  <div class="d-flex justify-content-between">
                    <div>
                      <div>
                        <label class="form-check-label fw-bold" :for="record.id">{{ record.jenis }}</label>
                      </div>
                      <div class="text-secondary">
                        {{ record.keterangan }}
                      </div>
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" :id="record.id"
                        @click="update(record.id, record.nilai, index)" v-model="record.nilai">
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>

<style>
.form-check-input:checked {
  background-color: #08b77e !important;
  border-color: #08b77e !important;
}
</style>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom } from "@/assets/js/function";

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  // components: {
  //   Form,
  //   Field,
  // },
  name: "editlocation",
  data() {
    return {
      filter: true,
      title: "Settings",
      title1: "manage your settings",
      config: "",
      api: "",
      foto_ktp_img: "",
      googleMap: "",

      //data
      currentData: {
      },
      DataList: [],
      jenisEnglishList: [
        {
          nama: "bpjs_kesehatan",
          nama_eng: "BPJS Kesehatan"
        },
        {
          nama: "bpjs_tk",
          nama_eng: "BPJS TK"
        },
        {
          nama: "lokasi_absensi",
          nama_eng: "Attendance Location"
        },
        {
          nama: "form_kasbon",
          nama_eng: "Cash Advance Form"
        },

        {
          nama: "form_dinas",
          nama_eng: "Oversea Form"
        },
        {
          nama: "form_izin",
          nama_eng: "Leave Form"
        },
        {
          nama: "form_reimburse",
          nama_eng: "Reimburese Form"
        },

        {
          nama: "pph21",
          nama_eng: "PPH21",
        },
        {
          nama: "form_cuti",
          nama_eng: "Annual Leave Form",
        },
        {
          nama: "approval_leveling",
          nama_eng: "Approval Leveling",
        },
        {
          nama: "radius_lock_absensi",
          nama_eng: "Attendance Radius Lock",
        },
        {
          nama: "form_lembur",
          nama_eng: "Overtime Form",
        },
        {
          nama: "break_button",
          nama_eng: "Break",
        },
      ],
      currentDate: new Date,
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    this.index();

  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  methods: {
    index() {
      ShowLoading();


      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'pengaturan_index';
      } else {
        this.api = base_url + 'pengaturan_index';
      }

      axios
        .post(this.api, '', this.config)
        .then((response) => {
          if (response.data.status == true) {



            response.data.data.forEach((item, index) => {

              let value = '';
              if (item.nilai == '1') {
                value = true;
              } else {
                value = false;
              }
              const index2 = this.jenisEnglishList.findIndex((item2) => item2.nama == item.jenis);
              let jenis_baru = "";
              if (index2 > -1) {
                jenis_baru = this.jenisEnglishList[index2].nama_eng;
              } else {
                jenis_baru = item.jenis;
              }
              let item_prepare = { id: item.id, jenis: jenis_baru, nilai: value, keterangan: item.keterangan };

              this.DataList.push(item_prepare);
            });



            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },


    update(id, nilai, index) {
      ShowLoading();
      let formData = new FormData();

      // Object.entries(this.currentData).forEach(([key, value]) => {
      //   formData.append(key, value);
      // });

      if (nilai == true) {
        nilai = '0';
      } else {
        nilai = '1';
      }
      formData.append('id', id);
      formData.append('nilai', nilai);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'pengaturan_update';
      } else {
        this.api = base_url + 'pengaturan_update';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
            const value_before = !this.DataList[index].nilai
            this.DataList[index].nilai = value_before;
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>
