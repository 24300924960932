<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">
              <div class="row">
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Date </label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker v-model="startDate" class="picker" :editable="true" :clearable="false" required />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Date </label>
                    <div class="input-groupicon" style="z-index: 100;">
                      <datepicker v-model="endDate" class="picker" :editable="true" :clearable="false" required />
                      <a class="addonset">
                        <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Generate</button>
                  <!-- <router-link to="departement" class="btn btn-cancel">Cancel</router-link> -->
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>

<style>
.ant-picker-focused {
  border-color: #08b77e !important;
  box-shadow: 0 0 0 2px rgba(24, 255, 74, 0.2);
  border-right-width: 1px !important;
  outline: 0;
}

.ant-picker {
  border-radius: 5px !important;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #08b77e !important;
  border-right-width: 1px !important;
}
</style>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom } from "@/assets/js/function";
import { ref } from 'vue';

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

const month = ref({
  month: new Date().getMonth(),
  year: new Date().getFullYear()
});

export default {
  // components: {
  //   Form,
  //   Field,
  // },
  name: "editemployee",
  data() {
    return {
      filter: true,
      title: "Payroll Report",
      title1: "generate payroll report",
      config: "",
      api: "",

      //data
      currentData: {
      },
      month: month,
      startDate: new Date,
      endDate: new Date,
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  methods: {

    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    insert() {
      ShowLoading();
      let formData = new FormData();


      // const newDate = new Date(this.month);
      // let year = newDate.getFullYear();
      // let month = String(newDate.getMonth() + 1).padStart(2, '0');

      // const formattedDate1 = `${year}-${month}`;

      // formData.append('periode', formattedDate1);

      const newDate = new Date(this.startDate);
      let year = newDate.getFullYear();
      let month = String(newDate.getMonth() + 1).padStart(2, '0');
      let day = String(newDate.getDate()).padStart(2, '0');

      const formattedDate1 = `${year}-${month}-${day}`;

      const newDate2 = new Date(this.endDate);
      year = newDate2.getFullYear();
      month = String(newDate2.getMonth() + 1).padStart(2, '0');
      day = String(newDate2.getDate()).padStart(2, '0');

      const formattedDate2 = `${year}-${month}-${day}`;

      formData.append('dfrom', formattedDate1);
      formData.append('dto', formattedDate2);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'report_payroll';
      } else {
        this.api = base_url + 'report_payroll';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            CloseLoading();
            window.open(response.data.data.file_report, "_blank");
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>

