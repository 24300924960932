<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar-menu></sidebar-menu>
        <div class="page-wrapper">
            <div class="content">
                <div class="row">
                    <!-- Chart -->
                    <div class="col-12  d-flex justify-content-end">
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div class="form-group">
                                <!-- <label>Choose Company<span class="text-danger">*</span></label> -->
                                <vue-select :options="companyList"
                                    :settings="{ settingOption: value, settingOption: value }" v-model="companyselected"
                                    placeholder="None" @select="setCompany()">
                                </vue-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card border-success">
                            <div class="card-header">
                                <h5 class="card-title">Attendance</h5>
                            </div>
                            <div class="card-body donut">
                                <apexchart type="pie" height="250" :options="donutAreaChart.donutchart"
                                    :series="donutAreaChart.series" v-if="showChart == true"></apexchart>
                                <div class="table-responsive dataview datatableIndex" v-if="showChart == false">
                                    <a-table :columns="columns2" :data-source="data2">
                                        <template #bodyCell="{ }">
                                            <template v-if="column.key === 'id'">
                                                <div class="productimgname">
                                                    <router-link to="approval" class="product-img"
                                                        v-if="record.lampiran != null">
                                                        <img :src="record.lampiran" alt="lampiran" />
                                                    </router-link>
                                                    <router-link to="approval">{{ record.judul
                                                    }} ({{ record.tanggal }})</router-link>
                                                </div>
                                            </template>
                                        </template>
                                    </a-table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /Chart -->

                    <div class="col-lg-6 col-sm-12 col-12 d-flex">
                        <div class="card flex-fill border-success">
                            <div class="card-header pb-0 d-flex justify-content-between align-items-center">
                                <h4 class="card-title mb-0">Need your approval</h4>
                                <div class="dropdown">
                                    <a href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="false"
                                        class="dropset">
                                        <i class="fa fa-ellipsis-v"></i>
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <li>
                                            <router-link to="approval" class="dropdown-item">Manage Approval</router-link>
                                        </li>
                                        <!-- <li>
                                            <router-link to="addproduct" class="dropdown-item">Product Add</router-link>
                                        </li> -->
                                    </ul>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive dataview datatableIndex" style="max-height: 250px;">
                                    <a-table :columns="columns" :data-source="data">
                                        <template #bodyCell="{ column, record }">
                                            <template v-if="column.key === 'id'">
                                                <div class="productimgname">
                                                    <router-link to="approval" class="product-img"
                                                        v-if="record.lampiran != null">
                                                        <img :src="record.lampiran" alt="lampiran" />
                                                    </router-link>
                                                    <router-link to="approval">{{ record.judul
                                                    }} ({{ record.tanggal }})</router-link>
                                                </div>
                                            </template>
                                        </template>
                                    </a-table>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>



            </div>
        </div>
    </div>
</template>

<style>
.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-right {

    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    /* background-color: red; */
}
</style>
<script>

import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup } from "@/assets/js/function";

export default {
    data() {
        return {
            donutAreaChart: {
                donutchart: {
                    chart: {
                        id: "vuechart-example",
                    },

                    chart: {
                        type: 'pie',
                        height: 350,
                        stacked: true,
                        toolbar: {
                            show: false,
                        },
                        dataLabels: {
                            enabled: true,
                        },
                    },
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'right',

                    },
                    labels: ['Present', 'Late', 'Absent', 'Annual Leave', 'Leave'],
                    colors: ['#08B67E', '#FFC42F', '#FA547C', '#5271FF', '#ACCE25'],
                },
                series: [],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }

                }],
            },
            data: [
                // {
                //     id: 1,
                //     Sno: "1",
                //     lampiran: "product22.jpg",
                //     judul: "[MENUNGGU] - Pengajuan IZIN ",
                //     tanggal: "18 Jan 2024",
                //     token: ""
                //     // Price: "$891.2",
                // },

            ],
            columns: [
                {
                    title: "No",
                    dataIndex: "Sno",
                    sorter: {
                        compare: (a, b) => {
                            a = a.Sno.toLowerCase();
                            b = b.Sno.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Form",
                    dataIndex: "judul",
                    key: "id",
                    sorter: {
                        compare: (a, b) => {
                            a = a.judul.toLowerCase();
                            b = b.judul.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                // {
                //     title: "Price",
                //     dataIndex: "Price",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.Price.toLowerCase();
                //             b = b.Price.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
            ],
            columns2: [],
            data2: [],
            // value: "",

            companyList: [],
            config: "",
            api: "",
            showChart: false,

            //data
            companyselected: "",

            //token
            token: "",

        };
    },

    methods: {
        index() {
            ShowLoading();
            let formData = new FormData();
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().slice(0, 10);


            formData.append("periode", formattedDate);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'dashboard_absensi';
            } else {
                this.api = base_url + 'dashboard_absensi';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        this.showChart = false;
                        if (response.data.data.hadir == 0 && response.data.data.terlambat == 0 && response.data.data.absen == 0) {
                            this.showChart = false;
                        } else {
                            this.donutAreaChart.series = [response.data.data.hadir, response.data.data.terlambat, response.data.data.absen]
                            this.showChart = true;
                        }

                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });

        },
        dashboard_notif() {
            ShowLoading();
            let formData = new FormData();

            formData.append("jenis", 'pengajuan');

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'dashboard_notif_new';
            } else {
                this.api = base_url + 'dashboard_notif_new';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {

                        response.data.data.forEach((item, index) => {
                            if (item.token != '') {
                                let item_prepare = { ...item, Sno: (index + 1) + "", id: index };
                                this.data.push(item_prepare);
                            }

                        });
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });

        },
        dashboard_index() {
            ShowLoading();
            let formData = new FormData();
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().slice(0, 10);


            formData.append("periode", formattedDate);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'dashboard_index';
            } else {
                this.api = base_url + 'dashboard_index';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status == true) {
                        localStorage.setItem('nama_user', response.data.data.fullname);
                        localStorage.setItem('tipe_user', response.data.data.tipe);

                        response.data.data.company.forEach((item) => {
                            let item_prepare = { id: item.token_refresh, text: item.company_name, };
                            this.companyList.push(item_prepare);
                        });

                        //set company
                        const index_company = this.companyList.findIndex((record) => record.text === response.data.data.nama_company);

                        if (index_company > -1) {
                            this.companyselected = this.companyList[index_company].id;
                        }

                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);

                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });

        },
        setCompany() {
            Cookies.set("token_refresh", this.companyselected);
            this.token = this.companyselected;
            this.companyList = [];
            this.data = [];
            this.config = {
                headers: { Authorization: `Bearer ` + this.token },
                timeout: 30000,
            }

            this.index();
            this.dashboard_notif();
            this.dashboard_index();
        }


    },

    created() {
        this.token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 30000,
        }
        this.index();
        this.dashboard_notif();
        this.dashboard_index();
    }


};
</script>
