<template>
  <div class="account-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <div class="account-content">
        <div class="login-wrapper">

          <div class="login-img">
            <img src="../../../assets/img/login.png" alt="img" size="10" />
          </div>
          <div class="login-content">
            <div class="login-userset">
              <div class="login-logo logo-normal">
                <img src="../../../assets/img/logo.png" alt="img" />
              </div>
              <router-link to="/index" class="login-logo logo-white">
                <img src="../../../assets/img/logo-white.png" alt="" />
              </router-link>
              <div class="login-userheading">
                <h3>Sign In</h3>
                <h4>Please login to your account</h4>
              </div>
              <Form class="login" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                <div class="form-login">
                  <label>Phone Number</label>
                  <div class="form-addons">
                    <Field name="phone" type="text" v-model="phone" class="form-control" aria-placeholder=""
                      :class="{ 'is-invalid': errors.phone }" :maxlength="14" />

                    <div class="invalid-feedback">{{ errors.phone }}</div>
                    <div class="emailshow text-danger" id="email"></div>

                    <!-- <img src="../../../assets/img/icons/mail.svg" alt="img" v-if="!(errors.email)" /> -->
                    <div class="position-absolute me-2 end-0 top-0 h-100 d-flex align-items-center"
                      v-if="!(errors.phone)">
                      <i class='bx bx-phone text-secondary' alt="img"></i>
                    </div>

                  </div>
                </div>
                <!-- <div class="form-login">
                  <label>Password</label>
                  <div class="pass-group">
                    <Field v-if="showPassword" name="password" type="text" value="123456" class="form-control pass-input"
                      :class="{ 'is-invalid': errors.password }" />
                    <Field v-else name="password" type="password" value="123456" class="form-control pass-input"
                      :class="{ 'is-invalid': errors.password }" />
                    <span @click="toggleShow" class="fas toggle-password" :class="{
                      'fa-eye': showPassword,
                      'fa-eye-slash': !showPassword,
                    }"></span>
                    <div class="invalid-feedback">{{ errors.password }}</div>
                    <div class="emailshow text-danger" id="password"></div>
                  </div>
                </div> -->
                <!-- <div class="form-login">
                  <div class="alreadyuser">
                    <h4>
                      <router-link to="forgetpassword" class="hover-a">Forgot Password?</router-link>
                    </h4>
                  </div>
                </div> -->
                <div class="form-login">
                  <button type="submit" class="btn btn-login">Sign In</button>
                  <!-- <router-link class="btn btn-login" to="pin"></router-link> -->
                </div>
              </Form>
              <div class="signinform text-center d-flex justify-content-between">
                <div>
                  Login as
                  <a href="" @click="setTipeLogin('admin');" class="hover-a" v-if="showAdmin">Admin</a>
                  <a href="" @click="setTipeLogin('owner')" class="hover-a" v-if="showOwner">Owner</a>
                </div>

                <div>
                  <h4>
                    Don’t have an account?
                    <a class="hover-a" @click="signUp">Sign up</a>
                    <!-- <a href="www.google.com" class="hover-a">Sign Up</a> -->
                  </h4>
                </div>
              </div>
              <!-- <div class="form-setlogin">
                <h4>Or sign up with</h4>
              </div> -->
              <!-- <div class="form-sociallink">
                <ul>
                  <li>
                    <a href="javascript:void(0);">
                      <img src="../../../assets/img/icons/google.png" class="me-2" alt="google" />
                      Sign Up using Google
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <img src="../../../assets/img/icons/facebook.png" class="me-2" alt="google" />
                      Sign Up using Facebook
                    </a>
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
// import { ref } from "vue";
// import { useStore } from "vuex";
import { Form, Field } from "vee-validate";
// import { router } from "../../../router";
// import VueRouter from "vue-router";
import * as Yup from "yup";
import axios from "axios";
import { goPage, ShowLoading, CloseLoading, base_url } from "@/assets/js/function";


export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      showPassword: false,
      password: null,
      phone: "",
      showAdmin: true,
      showOwner: false,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    setTipeLogin(status) {
      ShowLoading();
      localStorage.setItem('tipe_login', status); goPage('');
    },

    signUp() {
      ShowLoading();
      // let formData = new FormData();
      // formData.append("", "");

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'status_layanan?layanan=jempolku.gilgamax';
      } else {
        this.api = base_url + 'status_layanan?layanan=jempolku.gilgamax';
      }
      axios
        .get(this.api,)
        .then((response) => {
          if (response.data.status == true) {
            window.open(response.data.link_register, "_blank");
            CloseLoading();
          }


          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });

    },
  },
  created() {

    if (localStorage.getItem('tipe_login') == undefined ||
      localStorage.getItem('tipe_login') == 'owner' ||
      localStorage.getItem('tipe_login') == null) {
      this.showAdmin = true;
      this.showOwner = false;
    } else {
      this.showAdmin = false;
      this.showOwner = true;
    }

    CloseLoading();
  },
  setup() {
    // let users = localStorage.getItem("storedData");
    // if (users === null) {
    //   let password = [
    //     {
    //       phone: "081234567890",
    //     },
    //   ];
    //   const jsonData = JSON.stringify(password);
    //   localStorage.setItem("storedData", jsonData);
    // }
    const schema = Yup.object().shape({
      phone: Yup.string().required("Phone Number is required").min(11, "Phone Number must be at least 11 characters"),
      // password: Yup.string()

      //   .required("Password is required"),
    });
    const onSubmit = (values) => {
      localStorage.setItem("phone", values.phone);
      goPage('pin');

    };
    return {
      schema,
      onSubmit,
    };
  },
};
</script>
