<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">
              <div class="row">
                <!-- <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Location <span class="text-danger">*</span></label>
                    <vue-select :options="LocationList" :settings="{ settingOption: value, settingOption: value }"
                      v-model="currentData.lokasi_id" placeholder="None" required>
                    </vue-select>
                  </div>
                </div> -->
                <div class="col-lg-3 col-sm-3 col-12">
                  <div class="form-group">
                    <label>Type of news</label>
                    <vue-select :options="tipe_news" v-model="currentData.tipe" placeholder="Select type" />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-12">
                  <div class="form-group">
                    <label>Directed to</label>
                    <vue-select :options="ditujukan_kepada_list" v-model="currentData.ditujukan_kepada"
                      placeholder="Select" />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6 col-12">
                  <div class="form-group" v-if="currentData.ditujukan_kepada == 'LOCATION'">
                    <div class="d-flex justify-content-between ">
                      <label>Location <span class="text-danger">*</span></label>
                    </div>

                    <multi-select :options="needData1" :selected-options="locations" style="z-index:504"
                      placeholder="select location" @select="onSelectLocations">
                    </multi-select>
                  </div>
                  <div class="form-group" v-if="currentData.ditujukan_kepada == 'EMPLOYEE'">
                    <div class="d-flex justify-content-between ">
                      <label>Employee <span class="text-danger">*</span></label>
                    </div>
                    <!-- <model-select :options="data_marketing" placeholder="Select Marketing"
                      v-model="this.currentData.id_marketing" style="z-index:500" :isError="marketingCek">
                    </model-select> -->
                    <multi-select :options="needData2" :selected-options="employees" style="z-index:504"
                      placeholder="select Employee" @select="onSelectEmployees">
                    </multi-select>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Title <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.judul" placeholder="" required />
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.tipe == 'EVENT'">
                  <div class="form-group">
                    <label>Date <span class="text-danger">*</span></label>
                    <input type="datetime-local" id="tanggal" name="tanggal" v-model="currentData.tanggal">
                  </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12" v-if="currentData.tipe == 'EVENT'">
                  <div class="form-group">
                    <label>Notify before event/news<span class="text-danger">*</span></label>
                    <div class="position-relative">
                      <input type="number" v-model="currentData.notif" placeholder="" max="100" required />
                      <div class="position-absolute end-0 top-0 me-5 mt-2">
                        minutes
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Deskripsi <span class="text-danger">*</span></label>
                    <textarea class="form-control" v-model="currentData.deskripsi" required>
                    </textarea>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Lampiran</label>
                    <input type="file" class="form-control" ref="lampiran" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Insert</button>
                  <router-link to="news" class="btn btn-cancel">Cancel</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.form-group input[type="datetime-local"] {
  border: 1px solid rgba(145, 158, 171, 0.32);
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #637381;
  padding: 10px 15px;
  border-radius: 5px;
}

.form-group input[type="number"] {
  border: 1px solid rgba(145, 158, 171, 0.32);
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #637381;
  padding: 10px 15px;
  border-radius: 5px;
}

.form-group input[type="datetime-local"]:focus {
  border: 1px solid rgba(82, 183, 127, 255);

}

.form-group input[type="number"]:focus {
  border: 1px solid rgba(82, 183, 127, 255);

}
</style>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom } from "@/assets/js/function";
import { ModelSelect, MultiSelect } from 'vue-search-select';

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {

    ModelSelect,
    MultiSelect

  },
  name: "editemployee",
  data() {
    return {
      filter: true,
      title: "Insert News & Events",
      title1: "add new news and events",
      config: "",
      api: "",
      value: "",

      ditujukan_kepada_list: ['EMPLOYEE', 'LOCATION'],
      tipe_news: ['NEWS', 'EVENT'],

      //data
      currentData: {
        ditujukan_kepada: "LOCATION",
        tanggal: this.getCurrentDateTime(),
        notif: 0,
        tipe: 'NEWS',
      },
      currentDate: new Date,
      needData1: [],
      needData2: [],

      ///multi select
      locations: [],
      employees: [],

      lastSelectItemLocation: {},
      lastSelectItemEmployee: {},
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    this.preparedData();
    // this.LocationList.push({ id: 0, value: 0, text: 'SEMUA' });

  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  methods: {
    getCurrentDateTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },
    preparedData() {

      const needData1 = JSON.parse(localStorage.getItem('needData1'));

      needData1.forEach((item, index) => {
        const item_prepare = { value: item.id, text: item.nama_lokasi };
        this.needData1.push(item_prepare);
      });



      const needData2 = JSON.parse(localStorage.getItem('needData2'));

      needData2.forEach((item, index) => {
        const item_prepare = { value: item.id, text: item.nama };
        this.needData2.push(item_prepare);
      });



    },
    onSelectLocations(items, lastSelectItem) {
      this.locations = items
      this.lastSelectItemLocation = lastSelectItem
    },
    onSelectEmployees(items, lastSelectItem) {
      this.employees = items
      this.lastSelectItemEmployee = lastSelectItem
    },

    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);

      });

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'owners/news/insert';
      } else {
        this.api = base_url + 'owners/news/insert';
      }
      formData.delete('lokasi_id');
      formData.delete('employee_id');


      if (this.currentData.ditujukan_kepada == 'LOCATION') {
        let id_lokasi = [];
        this.locations.forEach((item, index) => {
          id_lokasi.push(item.value)
        });
        formData.append('lokasi_id', id_lokasi.join(','));
      } else {
        let employee_id = [];
        this.employees.forEach((item, index) => {
          employee_id.push(item.value)
        });
        formData.append('employee_id', employee_id.join(','));
      }



      formData.delete("lampiran");
      if (this.$refs.lampiran.value !== "") {
        formData.append("lampiran", this.$refs.lampiran.files[0]);
      }

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }


      axios
        .post(this.api, formData, this.config)
        .then((response) => {

          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage('news');
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

  },

};
</script>
