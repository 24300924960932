<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />

        <!-- /add -->
        <div class="card">
          <div class="card-body">
            <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
            <form @submit.prevent="insert">
              <pageheader title="Employee Data" title1="" />

              <div class="row">
                <div class="col-12 mb-3">
                  <embed :src="googleMap" type="" id="lokasi" class="w-100 h-100 pe-4 rounded">
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Location Name <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.nama_lokasi" placeholder="" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Location Code <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.kode_lokasi" placeholder="" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Lat <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.lat" placeholder="" required />
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                  <div class="form-group">
                    <label>Long <span class="text-danger">*</span></label>
                    <input type="text" v-model="currentData.lng" placeholder="" required />
                  </div>
                </div>
                <div class="col-lg-12 mb-3">
                  <div class="d-flex justify-content-end">
                    <button type="button" class="btn btn-primary" @click="cekGoogleMap">Cek</button>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Address</label>
                    <textarea class="form-control" v-model="currentData.alamat">
                    </textarea>
                  </div>
                </div>

                <div class="col-lg-12">
                  <button type="submit" class="btn btn-submit me-2">Insert</button>
                  <router-link to="employee" class="btn btn-cancel">Cancel</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
        <!-- /add -->
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, formatCurrency, rupiah, rupiahoutput, rupiahInput, AlertBottom } from "@/assets/js/function";

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  // components: {
  //   Form,
  //   Field,
  // },
  name: "editemployee",
  data() {
    return {
      filter: true,
      title: "Insert Location",
      title1: "add new location",
      config: "",
      api: "",
      foto_ktp_img: "",
      googleMap: "",

      //data
      currentData: {
        alamat: "",
        nama_lokasi: "",
        kode_lokasi: "",
        lat: "0",
        lng: "0",
      },
      currentDate: new Date,
    };
  },

  created() {
    const token = Cookies.get("token_refresh");
    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }
    this.googleMap = 'https://maps.google.com/maps?q=1,1&output=embed';

  },
  // setup() {

  //   const schema = Yup.object().shape({
  //     name: Yup.string().required("Phone Number is required"),

  //   });

  //   return {
  //     schema,

  //   };
  // },
  methods: {
    rupiah, rupiahoutput,
    formatCurrency(number, index) {
      this.currentEmployee.salary[index].nilai = rupiahInput(number);
    },
    getNameFileUrl(url) {
      const parts = url.split('/');
      const fileName = parts[parts.length - 1];
      return fileName;
    },
    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      //deleteemplpyee id because its necessary

      //set to indonesia again to save
      formData.delete("lat");
      formData.delete("lng");

      formData.append('latlng', this.currentData.lat + "," + this.currentData.lng);

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'lokasi_insert';
      } else {
        this.api = base_url + 'lokasi_insert';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage("location");
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    cekGoogleMap() {
      ShowLoading();
      this.googleMap = 'https://maps.google.com/maps?q=' + this.currentData.lat + ',' + this.currentData.lng + '&output=embed';
      CloseLoading();
    }

  },

};
</script>
