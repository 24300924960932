<template>
  <div class="account-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <div class="account-content">
        <div class="login-wrapper">

          <div class="login-img">
            <img src="../../../assets/img/login.png" alt="img" size="10" />
          </div>
          <div class="login-content">
            <div class="login-userset">
              <div class="login-logo logo-normal">
                <img src="../../../assets/img/logo.png" alt="img" />
              </div>
              <router-link to="/index" class="login-logo logo-white">
                <img src="../../../assets/img/logo-white.png" alt="" />
              </router-link>
              <div class="login-userheading">
                <h3>Confirmation PIN</h3>
                <h4>Please enter your new pin again</h4>
              </div>
              <Form class="login" @submit="onSubmit">
                <div class="form-login">
                  <label>Pin</label>
                  <div class="form-addons ">
                    <div class="d-flex gap-2">
                      <div v-for="(field, index) in pinFields" :key="index">
                        <input v-model="pinFields[index]" @input="handleInput(index)" @keydown="handleKeyDown(index)"
                          :maxlength="1" :autofocus="index === 0" type="text" :ref="'pinField' + (index)"
                          :id="'pin' + (index + 1)" />
                        <!-- <div class="invalid-feedback">{{ errors['pin' + (index)] }}</div>
                        <div class="text-danger" :id="'pin' + (index)"></div> -->
                      </div>
                      <!-- <div>
                        <Field name="pin1" type="text" v-model="pin1" class="form-control" aria-placeholder=""
                          :class="{ 'is-invalid': errors.pin1 }" autofocus />
                        <div class="invalid-feedback">{{ errors.pin1 }}</div>
                        <div class="text-danger" id="pin1"></div>
                      </div> -->

                    </div>





                  </div>
                </div>
                <!-- <div class="form-login">
                  <label>Password</label>
                  <div class="pass-group">
                    <Field v-if="showPassword" name="password" type="text" value="123456" class="form-control pass-input"
                      :class="{ 'is-invalid': errors.password }" />
                    <Field v-else name="password" type="password" value="123456" class="form-control pass-input"
                      :class="{ 'is-invalid': errors.password }" />
                    <span @click="toggleShow" class="fas toggle-password" :class="{
                      'fa-eye': showPassword,
                      'fa-eye-slash': !showPassword,
                    }"></span>
                    <div class="invalid-feedback">{{ errors.password }}</div>
                    <div class="emailshow text-danger" id="password"></div>
                  </div>
                </div> -->
                <!-- <div class="form-login">
                  <div class="alreadyuser">
                    <h4>
                      <router-link to="forgetpassword" class="hover-a">Forgot Password?</router-link>
                    </h4>
                  </div>
                </div> -->
                <div class="form-login">
                  <button class="btn btn-login" type="submit" ref="buttonSignin">Save</button>
                  <!-- <router-link class="btn btn-login" to="index"></router-link> -->
                </div>
              </Form>
              <div class="signinform text-center d-flex justify-content-between">
                <div>
                  <router-link to="/" class="hover-a">Change account</router-link>
                </div>
                <div>
                  <h4>
                    Make new one again ?
                    <router-link to="/reset-pin" class="hover-a">reset PIN</router-link>
                  </h4>
                </div>

              </div>

              <!-- <div class="form-setlogin">
                <h4>Or sign up with</h4>
              </div> -->
              <!-- <div class="form-sociallink">
                <ul>
                  <li>
                    <a href="javascript:void(0);">
                      <img src="../../../assets/img/icons/google.png" class="me-2" alt="google" />
                      Sign Up using Google
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <img src="../../../assets/img/icons/facebook.png" class="me-2" alt="google" />
                      Sign Up using Facebook
                    </a>
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { Form, Field } from "vee-validate";
import { router } from "../../../router";
import VueRouter from "vue-router";
import * as Yup from "yup";
import axios from "axios";
import Cookies from "js-cookie";

import { AlertPopup, CloseLoading, ShowLoading, base_url, timeout, ErrorConnectionTimeOut, goPage } from "@/assets/js/function";

export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      showPassword: false,
      password: null,
      pinFields: ['', '', '', ''],
      token: "",
      config: "",
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    handleInput(index) {

      // Remove non-numeric characters
      this.pinFields[index] = this.pinFields[index].replace(/[^0-9]/g, '');

      // Move focus to the next field if the current field is filled
      if (this.pinFields[index].length === 1 && index < this.pinFields.length - 1) {
        this.$refs['pinField' + (index + 1)][0].focus();
      }

      if (index == 3) {
        this.$refs.buttonSignin.click();
      }
    },
    handleKeyDown(index) {

      // Prevent non-numeric input and move focus to the previous field on backspace
      if (event.key.match(/[^0-9]/) && event.key !== 'Backspace') {
        event.preventDefault();
      } else if (event.key === 'Backspace' && index > 0 && this.pinFields[index].length === 0) {
        this.$refs['pinField' + (index - 1)][0].focus();
      }
    },

    onSubmit() {
      ShowLoading();
      const pinFinal = document.getElementById("pin1").value + document.getElementById("pin2").value + document.getElementById("pin3").value + document.getElementById("pin4").value;
      let formData = new FormData();

      formData.append("pin", pinFinal);
      formData.append("pin_konfirmasi", localStorage.getItem('pin-confirm'));
      formData.append("phone", localStorage.getItem('phone'));

      const token = Cookies.get("token_refresh");
      let config = {
        headers: { Authorization: `Bearer ` + token },
        timeout: timeout,
      }

      let api = "";
      if (process.env.NODE_ENV === "development") {
        api = base_url + 'pin_insert';
      } else {
        api = base_url + 'pin_insert';
      }


      axios
        .post(api, formData, config)
        .then((response) => {
          if (response.data.status == true) {
            Cookies.set("token_refresh", response.data.token_refresh);
            localStorage.setItem('pin-confirm', '');
            CloseLoading();
            goPage('index');
          } else {
            this.pinFields = ['', '', '', ''];
            AlertPopup("error", "", response.data.message, 1500, false);
            setTimeout(function () {
              document.getElementById("pin1").focus();
            }, 1800);




          }

        })
        .catch((error) => {
          // this.isi_button = `<div class="fs-7">Login</div>`;
          ErrorConnectionTimeOut(error);
        });
    }


  },
  setup() {
    let users = localStorage.getItem("storedData");
    if (users === null) {
      let password = [
        {
          email: "081234567890",
          password: "123456",
        },
      ];
      const jsonData = JSON.stringify(password);
      localStorage.setItem("storedData", jsonData);
    }
    const schema = Yup.object().shape({
      pin1: Yup.string().min(1, "Pin must be at least 1 characters").required("Pin is required"),
      pin2: Yup.string()
        .min(1, "Pin must be at least 1 characters")
        .required("Pin is required"),
      pin3: Yup.string()
        .min(1, "Pin must be at least 1 characters")
        .required("Pin is required"),
      pin4: Yup.string()
        .min(1, "Pin must be at least 1 characters")
        .required("Pin is required"),

    });

    return {
      schema,
    };
  },
};
</script>
